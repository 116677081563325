import React, { useState, useRef, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Table, Modal } from 'antd';
import axios from 'axios';
import moment from 'moment/moment';
import FormModal from './FormModal';

export default function FormsTable({ subcategories, with_users }) {
    const [modal, contextHolder] = Modal.useModal();
    //const base_url = 'http://localhost:8000';
    const base_url = '/';
    const [tableParams, setTableParams] = useState({
        pagination: { "current": 1, "pageSize": 10, "total": 0 },
        filters: {},
        sorter: {},
        subcategory: (subcategories) ? subcategories[0]?.subcategory : "",
        user: null
    });
    const searchInput = useRef(null);
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState({
        columns: [],
        source: []
    });
    const [subcats, setSubcats] = useState(subcategories);
    const [users, setUsers] = useState([]);

    /* Modal Controls */
    //const [modalOpened, setModalOpened] = useState(false);
    const [editItem, setEditItem] = useState(null);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div className="p-1" onKeyDown={e => e.stopPropagation()}>
                <input type="text" className="form-control form-control-sm"
                    ref={searchInput}
                    placeholder='Escribe algo...'
                    value={(selectedKeys[0]) ? selectedKeys[0] : ""}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') { handleSearch(selectedKeys, confirm, dataIndex) }
                    }}
                />
                <div className="mt-1 d-flex justify-content-center">
                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    >
                        <span className="fas fa-search"></span>
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => clearFilters && handleReset(clearFilters, setSelectedKeys, confirm)}
                    >
                        Reset
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => { close(); }}
                    >
                        Salir
                    </button>
                </div>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) { setTimeout(() => searchInput.current?.select(), 100); }
        }
    });

    useEffect(() => {
        if (with_users) { getUsers(); }
        else { getItems(tableParams); }
    }, []);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };
    const handleReset = (clearFilters, setSelectedKeys, confirm) => {
        clearFilters();
        setSelectedKeys([]);
        confirm();
    };

    const handle_change = (pagination, filters, sorter, extra) => {
        const params = {
            ...tableParams,
            pagination: pagination,
            filters: filters,
            sorter: sorter,
        };

        setTableParams(prev => {
            return {
                ...prev,
                pagination: pagination,
                filters: filters,
                sorter: sorter
            };
        });

        getItems(params);
    };

    const getConnector = () => {
        axios.defaults.withCredentials = true;
        const conn = axios.create({
            baseURL: base_url,
            headers: {
                Accept: "application/json"
            }
        });

        conn.interceptors.response.use(
            response => response,
            error => {
                if (error.response.status === 401) {
                    window.location.href = '/login';
                }
                return Promise.reject(error);
            }
        );

        return conn;
    }

    const getItems = async (params) => {
        let connector = getConnector();

        try {
            setLoadingData(true);
            const response = await connector.get(`/api/forms_data`,
                {
                    params: params,
                });

            const { columns, items, total, current } = response.data;

            let ed_columns = columns.map(it => {
                let item = it;

                if (item.datatype === 'string' || item.datatype === 'integer' || item.datatype === 'selection') {
                    item = {
                        ...item,
                        filterDropdown: getColumnSearchProps(it.dataIndex).filterDropdown,
                        onFilterDropdownOpenChange: getColumnSearchProps(it.dataIndex).onFilterDropdownOpenChange
                    }
                }

                switch (item.datatype) {
                    case "selection":
                    case "date":
                    case "integer":
                    case "interval":
                    case "boolean":
                        item.width = 150;
                        break;
                    case "string":
                        item.width = 250;
                        break;
                    default:
                        item.width = 70;
                }

                return item;
            });

            if (!with_users) {
                ed_columns = [
                    {
                        title: "",
                        key: "edit_actions",
                        dataIndex: "id_fill",
                        width: 120,
                        render: (value, row) => (
                            <div>
                                <button type='button' className='btn btn-sm btn-light border'
                                    onClick={() => setEditItem(row)}
                                >
                                    <span className="fas fa-pen"></span>
                                </button>
                                <button type="button" className="btn btn-sm btn-light border ms-1" title="Eliminar"
                                    onClick={() => handle_delete(value)}
                                >
                                    <span className="fas fa-trash btn-light"></span>
                                </button>
                                <a href={`http://localhost:8000/notifications/${value}`} className='btn btn-sm btn-light border ms-1'>
                                    <span className='fas fa-exclamation-circle btn-light'></span>
                                </a>
                            </div>
                        )
                    },
                    ...ed_columns
                ];
            }

            setData(prev => {
                return {
                    ...prev,
                    columns: ed_columns,
                    source: items,
                }
            });
            setTableParams(prev => {
                return {
                    ...prev,
                    pagination: {
                        ...prev.pagination,
                        total: total,
                        current: current
                    }
                }
            });
        } catch (error) { console.log(error); }
        finally { setLoadingData(false); }
    }

    const handle_change_db = (value) => {
        let params = {
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                current: 1
            },
            subcategory: value
        };

        setTableParams(prev => {
            return {
                ...prev,
                pagination: {
                    ...tableParams.pagination,
                    current: 1
                },
                subcategory: value
            }
        });

        getItems(params);
    }

    const getUsers = async () => {
        let connector = getConnector();

        try {
            const response = await connector.get('api/company_users');
            const { data } = response;
            setUsers(data);
            setSubcats((data[0])? data[0]?.subcategories : []);

            let params = {
                ...tableParams,
                user: data[0]?.id,
                subcategory: data[0]?.subcategories[0]?.subcategory
            };

            setTableParams(prev => {
                return {
                    ...prev,
                    user: data[0]?.id,
                    subcategory: data[0]?.subcategories[0]?.subcategory
                }
            });
            getItems(params);
        } catch (error) { console.log(error); }
    }

    const handle_change_user = (value) => {
        let user = users.find(u => u.id === parseInt(value));
        setSubcats(user.subcategories);

        let params = {
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                current: 1
            },
            user: value,
            subcategory: user.subcategories[0]?.subcategory
        };

        setTableParams(prev => {
            return {
                ...prev,
                pagination: {
                    ...tableParams.pagination,
                    current: 1
                },
                user: value,
                subcategory: user.subcategories[0]?.subcategory ?? null
            }
        });




        getItems(params);
    }

    const handle_submit = async (edItem) => {
        let conn = getConnector();
        return conn.post('/api/update_data', edItem);
    }

    const handle_update = () => {
        getItems(tableParams);
    }

    const handle_delete = (value) => {
        modal.confirm({
            title: "Confirmar", content: "¿Desea eliminar el registro?", onOk: async () => {
                let conn = getConnector();

                try {
                    await conn.post('/api/delete_data', { id_fill: value });
                    handle_update();
                } catch (error) { console.log(error); }
            }
        });
    }

    const download_csv = async () => {
        const con = getConnector();

        try {
            let response = await con.get('/api/download_csv', { params: tableParams });

            /* start */

            const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]),response.data], { type: 'data:text/csv;charset=utf-8,' });
            const blobURL = window.URL.createObjectURL(blob);

            // Create new tag for download file
            const anchor = document.createElement('a');
            anchor.download = "registros.csv";
            anchor.href = blobURL;
            anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(
                ':'
            )
            anchor.click();

            // Remove URL.createObjectURL. The browser should not save the reference to the file.
            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                URL.revokeObjectURL(blobURL);
            }, 100);
        
            /* end */

        } catch (error) { console.log(error); }
}

return (
    <div>
        <div>
            {contextHolder}
        </div>
        <div className='overflow-hidden'>
            <div className="row mb-3">
                <div className="col-md-auto">
                    {
                        (!with_users) ? (
                            <a className='btn btn-sm btn-light border'
                                href={`/forms/create/${tableParams.subcategory}`}
                            >
                                <span className="fas fa-plus me-1"></span>
                                Agregar
                            </a>
                        ) : null
                    }
                </div>
                <div className="col-md mb-2"></div>
                <div className='col-md-auto mb-2'>
                    <div className="row">
                        {
                            (with_users) ? (
                                <>
                                    <label htmlFor="" className="col-md-auto col-form-label text-black"
                                        style={{ lineHeight: '0.8' }}
                                    >Usuario: </label>
                                    <div className="col-md">
                                        <select name="" id="" className="form-select form-select-sm"
                                            onChange={(e) => handle_change_user(e.target.value)}
                                        >
                                            {
                                                users.map(us => (
                                                    <option key={us.id} value={us.id}>{us.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </>
                            ) : null
                        }
                        <label htmlFor="" 
                            className="col-md-auto col-auto col-form-label text-black"
                            style={{ lineHeight: '0.8' }}
                        >
                            Base de datos:
                        </label>
                        <div className="col-md col">
                            <select name="" id="" className="form-select form-select-sm"
                                onChange={(e) => handle_change_db(e.target.value)}
                            >
                                {
                                    subcats.map(subcat => (
                                        <option key={subcat.id} value={subcat.subcategory}>{subcat.subcategory}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-auto mb-2">
                    <button className="btn btn-sm btn-secondary"
                        onClick={() => download_csv()}
                    >
                        <span className="fas fa-table me-1"></span>
                        Descargar CSV
                    </button>
                </div>
            </div>
        </div>
        <Table
            size='small'
            rowKey='id_fill'
            loading={loadingData}
            dataSource={data.source}
            columns={data.columns}
            onChange={handle_change}
            pagination={{ ...tableParams.pagination, position: ['bottomCenter'], showSizeChanger: true }}
            scroll={{ y: 400, }}
            bordered
        />

        <Modal
            title="Formulario"
            open={editItem}
            /* onOk={hideModal} */
            onCancel={() => setEditItem(null)}
            footer={null}
        >
            <FormModal
                item={editItem}
                data_columns={data.columns}
                onSubmit={handle_submit}
                onUpdate={handle_update}
            />
        </Modal>
    </div>
);
}

let main = document.getElementById('forms-table');

if (main) {
    let subcategories = JSON.parse(main.dataset.subcategories);
    let with_users = (main.dataset.with_users) ? Boolean(main.dataset.with_users) : false;

    const root = createRoot(main);
    root.render(
        <React.StrictMode>
            <FormsTable
                subcategories={subcategories}
                with_users={with_users}
            />
        </React.StrictMode>
    );
}