var toast1_el;
var toast1;
window.addEventListener('DOMContentLoaded', function(e) {
    toast1_el = document.querySelector('#toast1');
    toast1 = new bootstrap.Toast(toast1_el);

    Livewire.on('show_toast', function(text) {                
        toast1_el.querySelector('.text').innerHTML = text;
        toast1.show();
    });
});