import { useEffect, useState } from "react"
import moment from "moment";

const FormModal = ({ item, data_columns, onSubmit, onUpdate }) => {
    const [editItem, setEditItem] = useState(item);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setEditItem(item);
    }, [item]);

    useEffect(() => {
        setColumns(data_columns);
    }, [data_columns]);

    const handle_submit = async (e) => {
        e.preventDefault();

        let entries = columns.slice(3).map(col => {
            if(col.entry_id) {
                return { entry_id: col.entry_id, dataIndex: col.dataIndex  }
            }
        });

        try {
            setLoading(true);
            await onSubmit({editItem , entries});
            onUpdate();
        } catch (error) { console.log(error); } 
        finally {
            setLoading(false);
        }

        console.log(editItem);
    }

    return (
        <div>
            {
                (editItem) ?
                    <div>
                        <form onSubmit={handle_submit}>
                            <fieldset disabled={loading}>
                                <input type="hidden" name="" value={editItem.id_fill} />
                                {
                                    columns.map(col => {
                                        if (!col.entry_id) { return; }
                                        switch (col.datatype) {
                                            case "selection":
                                                return (
                                                    <div className="mb-3" key={col.entry_id}>
                                                        <label className='form-label' htmlFor="">{col.title}</label>
                                                        <select name={`entry_${col.entry_id}`} id="" className="form-select form-select-sm"
                                                            value={editItem[col.dataIndex] ?? ""}
                                                            onChange={(e) => setEditItem(prev => { return { ...prev, [col.dataIndex]: e.target.value } })}
                                                            required={!col.optional}
                                                        >
                                                            {
                                                                col.subitems.map(subit => (
                                                                    <option key={subit.id} value={subit.text}>{subit.text}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                )
                                            case "date":
                                                return (
                                                    <div className="mb-3" key={col.entry_id}>
                                                        <label className='form-label' htmlFor="">{col.title}</label>
                                                        <input name={`entry_${col.entry_id}`} type="date" className="form-control form-control-sm"
                                                            value={moment(editItem[col.dataIndex]).format('YYYY-MM-DD')}
                                                            onChange={(e) => setEditItem(prev => { return { ...prev, [col.dataIndex]: e.target.value } })}
                                                            required={!col.optional}
                                                        />
                                                    </div>
                                                )
                                            case "integer":
                                                return (
                                                    <div className="mb-3" key={col.entry_id}>
                                                        <label className='form-label' htmlFor="">{col.title}</label>
                                                        <input name={`entry_${col.entry_id}`} type="number" className="form-control form-control-sm"
                                                            value={editItem[col.dataIndex] ?? ""}
                                                            onChange={(e) => setEditItem(prev => { return { ...prev, [col.dataIndex]: e.target.value } })}
                                                            required={!col.optional}
                                                        />
                                                    </div>
                                                )
                                            case "string":
                                                return (
                                                    <div className="mb-3" key={col.entry_id}>
                                                        <label className='form-label' htmlFor="">{col.title}</label>
                                                        <input name={`entry_${col.entry_id}`} type="text" className="form-control form-control-sm"
                                                            value={editItem[col.dataIndex] ?? ""}
                                                            onChange={(e) => setEditItem(prev => { return { ...prev, [col.dataIndex]: e.target.value } })}
                                                            required={!col.optional}
                                                        />
                                                    </div>
                                                )
                                            case "interval":
                                                return (
                                                    <div className="mb-3" key={col.entry_id}>
                                                        <label className='form-label' htmlFor="">{col.title}</label>
                                                        <input name={`entry_${col.entry_id}`} type="number" className="form-control form-control-sm"
                                                            value={editItem[col.dataIndex] ?? ""}
                                                            onChange={(e) => setEditItem(prev => { return { ...prev, [col.dataIndex]: e.target.value } })}
                                                            required={!col.optional}
                                                        />
                                                    </div>
                                                )
                                            case "boolean":
                                                return (
                                                    <div className="mb-3" key={col.entry_id}>
                                                        <label className='form-label' htmlFor="">{col.title}</label>
                                                        <select name={`entry_${col.entry_id}`} className="form-select form-select-sm"
                                                            value={editItem[col.dataIndex] ?? "si"}
                                                            onChange={(e) => setEditItem(prev => { return { ...prev, [col.dataIndex]: e.target.value } })}
                                                            required={!col.optional}
                                                        >
                                                            <option value="si">Si</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </div>
                                                )
                                            default:
                                                return (<></>)
                                                break;
                                        }
                                    })
                                }
                                <div>
                                    <button className="btn btn-sm btn-dark px-3"
                                        type="submit"
                                    >
                                        Guardar
                                    </button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                    : null
            }
        </div>

    );
}

export default FormModal;